<template v-if="model">
  <div>
    <div
      class="bannerWrapper"
      :class="model.enabled ? '' : 'slide-hidden'"
      :style="
        'background-color:' +
        bg +
        ';background-image:url(' +
        model.img +
        ');background-position: right;'
      "
    >
      <div style="padding-left: 5%; width: 57%">
        <Editor
          :model="model.content"
          :language="language"
          :disableButtons="true"
        />
      </div>
    </div>
    <div class="settings">
      <div class="row">
        <div class="col-md">
          <div
            @click="language = 'ka'"
            class="languageSelector mr-3"
            :class="language == 'ka' ? 'selectedButtonColor' : ''"
          >
            KA
          </div>
          <div
            icon
            @click="language = 'en'"
            class="languageSelector mr-3"
            :class="language == 'en' ? 'selectedButtonColor' : ''"
          >
            EN
          </div>
          <div class="languageSelector mr-3">
            <v-icon
              class="icons"
              style="color: white"
              @click="editBannerPopup()"
              >mdi-image-plus</v-icon
            >
          </div>
          <div class="languageSelector mr-3">
            <v-icon class="icons" style="color: white" @click="toggleImage"
              >mdi-image-off</v-icon
            >
          </div>
          <div class="languageSelector mr-3">
            <v-icon
              class="icons"
              style="color: white"
              v-if="!model.enabled"
              @click="model.enabled = true"
              >mdi-eye-off</v-icon
            >
            <v-icon
              class="icons"
              style="color: white"
              v-else
              @click="model.enabled = false"
              >mdi-eye</v-icon
            >
          </div>
        </div>
        <div class="col-md-6">
          <div
            v-for="color in colors"
            :key="color"
            class="color"
            :class="color === bg ? 'selectedButtonColor' : null"
            :style="'background-color:' + color + ';'"
            @click="setColor(color)"
          ></div>
        </div>
      </div>
    </div>
    <uploadBanner :uploadBanner="uploadBanner" />
  </div>
</template>
<script>
import Api from "@/services/api";
import Editor from "@/components/manager/site/Editor.vue";
import uploadBanner from "@/components/manager/popup/site/UploadBanner.vue";
export default {
  props: ["model"],
  components: {
    uploadBanner,
    Editor,
  },
  data() {
    return {
      uploadBanner: false,
      imageOriginal: this.model.img,
      language: this.$i18n.locale,
      bg: this.model.bg,
      colors: [
        "#002C6C",
        "#F26334",
        "#454545",
        "#888B8D",
        "#B1B3B3",
        "#F4F4F4",
        "#FFFFFF",
        "#F05587",
        "#BD80B7",
        "#AF96D4",
        "#89AADB",
        "#00B6DE",
        "#008DBD",
        "#8DB9CA",
        "#22BCB9",
        "#71B790",
        "#7AC143",
        "#00AC4A",
        "#9DBB68",
        "#C1D82F",
        "#C4B000",
        "#FBB034",
        "#FF8200",
        "#B78B20",
      ],
    };
  },
  methods: {
    setColor(color) {
      this.bg = color;
      this.model.bg = color;
    },
    editBannerPopup() {
      this.uploadBanner = [];
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      Api.media("upload", formData).then(({ data }) => {
        this.model.img = data.url;
        this.imageOriginal = data.url;
      });
    },
    toggleImage() {
      console.log(this.model.img);
      console.log(this.imageOriginal);
      this.model.img.length < 1
        ? (this.model.img = this.imageOriginal)
        : (this.model.img = "");
    },
    closeBannersPopup(webImg) {
      if (webImg) {
        this.uploadFile(webImg);
      }
      this.uploadBanner = false;
    },
  },
};
</script>
<style scoped>
.bannerWrapper {
  width: 100%;
  min-height: 250px;
  border: 1px solid #dadada;
}
.color {
  min-width: 30px;
  min-height: 30px;
  display: inline-block;
  cursor: pointer;
}
.selectedButtonColor {
  border: 5px solid rgb(163, 163, 77);
}
.languageSelector {
  display: inline-block;
  padding: 5px;
  background: #008dbd;
  color: #fff;
  cursor: pointer;
  top: -6px;
  position: relative;
}
.slide-hidden {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
}
</style>
