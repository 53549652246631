<template>
  <div class="block">
    <Editor :model="model" />
  </div>
</template>
<script>
import Editor from "@/components/manager/site/Editor.vue";

export default {
  props: ["model"],
  components: {
    Editor,
  },
  data() {
    console.log(this.model);
    return {};
  },
};
</script>
<style scoped>
.block {
  display: inline-block;
  margin: 0px 10px 10px 0px;
  width: 100%;
}
@media only screen and (max-width: 820px) {
  .block {
    width: 100%;
  }
}
</style>