<template>
  <v-dialog
    v-if="dialogState"
    v-model="dialogState"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.edit") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-row>
          <v-col>
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.site.faq_question_ka') + ' *'"
              v-model="dialogState.question.ka"
            ></v-text-field>
          </v-col>
          <v-col> 
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.site.faq_question_en') + ' *'"
              v-model="dialogState.question.en"
            ></v-text-field>
          </v-col>
        </v-row>
        <Editor :model="dialogState.answer" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.closeEditQuestionPopup()">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="$parent.closeEditQuestionPopup(dialogState)">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@/components/manager/site/Editor.vue";
export default {
  components: { Editor },
  data: function () {
    return {
      dialogState: false,
    };
  },
  watch: {
    question(newVal) {
      if (newVal) {
        this.dialogState = JSON.parse(JSON.stringify(newVal));
      } else {
        this.dialogState = false;
      }
    },
  },
  props: ["question"],
};
</script>