export const validator = {

}

export const newItem = {
    name: {
        ka: "",
        en: ""
    },
    path: "/",
    banner: {
        enabled: false,
        img: "",
        bg: "#fff",
        content: {
            ka: "",
            en: "",
        },
    },
    bottom: {
        ka: "",
        en: "",
    },
    blocks: [{
            content: {
                ka: "",
                en: "",
            }
        },
        {
            content: {
                ka: "",
                en: "",
            },
        }
    ],
    content: {
        ka: "",
        en: "",
    },
}