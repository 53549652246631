export default {
    id: 0,
    content: {
        ka: '',
        en: ''
    },
    logo: {
        ka: null,
        en: null
    },
    logoNew: {
        ka: null,
        en: null
    }
}