<template>
  <div v-if="item">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>{{ $vuetify.lang.t("$vuetify.site.page_params") }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="col-12 col-md-4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.name_ka')"
                v-model="item.name.ka"
              ></v-text-field>
            </v-col>
            <v-col class="col-12 col-md-4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.name_en')"
                v-model="item.name.en"
              ></v-text-field>
            </v-col>
            <v-col class="col-12 col-md-4">
              <v-text-field
                :disabled="
                  item.path == '/contact_us' ||
                  item.path == '/members' ||
                  item.path == '/faq'
                "
                label="URI"
                v-model="item.path"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="item.path !== '/'">
        <v-expansion-panel-header>
          <h3>{{ $vuetify.lang.t("$vuetify.site.banner") }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Banner :model="item.banner" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header
          ><h3>
            {{ $vuetify.lang.t("$vuetify.site.main_content") }}
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <Editor :model="item.content" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="item.path === '/contact_us'">
        <v-expansion-panel-header>
          <h3>Google Map</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea v-model="item.googlemap"></v-textarea>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="item.path !== '/members'">
        <v-expansion-panel-header
          ><h3>
            {{ $vuetify.lang.t("$vuetify.site.sideblocks") }}
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <Blocks :model="item.blocks" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="item.path !== '/members'">
        <v-expansion-panel-header
          ><h3>
            {{ $vuetify.lang.t("$vuetify.site.bottomBlock") }}
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content
          ><BottomBlock :model="item.bottom"
        /></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="item.path === '/members'">
      <br />
      <h3 class="float-left ps-5">
        {{ $vuetify.lang.t("$vuetify.site.members") }}
      </h3>
      <v-btn class="float-right" @click="addMember()">
        <v-icon>mdi-plus</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.add") }}
      </v-btn>
      <div class="clear"></div>
      <br />
      <hr />
      <v-row>
        <v-col
          class="col-sm-12 col-md-4"
          v-for="member in item.members"
          :key="member.id"
        >
          <div class="member">
            <div
              class="img"
              :style="
                'background-image: url(' + member.logo[$i18n.locale] + ');'
              "
            ></div>
            <div class="desc">
              <div v-html="member.content[$i18n.locale]"></div>
            </div>
            <div class="clear"></div>
            <div class="text-end">
              <v-btn icon @click="editMemberPopup = member"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn icon @click="popupDelete(member.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-if="item.path === '/faq'">
      <br />
      <h3 class="float-left ps-5">
        {{ $vuetify.lang.t("$vuetify.site.faq") }}
      </h3>
      <v-btn class="float-right" @click="addFaq()">
        <v-icon>mdi-plus</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.add") }}
      </v-btn>
      <div class="clear"></div>
      <br />
      <hr />
      <v-expansion-panels>
        <v-expansion-panel v-for="(q, i) in questions" :key="i">
          <v-expansion-panel-header>
            <h3>{{ q.question[$i18n.locale] }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="q.answer[$i18n.locale]"></div>
            <div class="text-end">
              <v-btn icon @click="editQuestionPopup = q"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn icon @click="popupDelete(q.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <br />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="save()">
        <v-icon class="mr-3">mdi-content-save</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.save") }}
      </v-btn>
    </v-card-actions>
    <br />
    <br />
    <ConfirmRemoval :confirmRemoval="confirmRemoval" :item="itemToRemove" />
    <EditMember :member="editMemberPopup" />
    <EditQuestion :question="editQuestionPopup" />
  </div>
</template>

<script>
import Api from "@/services/api";
import Banner from "@/components/manager/site/Banner.vue";
import Editor from "@/components/manager/site/Editor.vue";
import Blocks from "@/components/manager/site/Blocks.vue";
import BottomBlock from "@/components/manager/site/BottomBlock.vue";
import PageHelper from "@/helpers/page";
import EditMember from "@/components/manager/popup/site/EditMember.vue";
import EditQuestion from "@/components/manager/popup/site/EditQuestion.vue";
import ConfirmRemoval from "@/components/manager/popup/site/ConfirmRemoval.vue";
import MemberModel from "@/models/member";
import FaqModel from "@/models/faq";
export default {
  components: {
    Banner,
    Editor,
    Blocks,
    BottomBlock,
    EditMember,
    EditQuestion,
    ConfirmRemoval,
  },
  data: function () {
    return {
      item: this.getData(),
      itemToRemove: false,
      confirmRemoval: false,
      editMemberPopup: false,
      editQuestionPopup: false,
      questions: [],
      repl: '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>'
    }
  },
  methods: {
    getData() {
      if (this.$route.params.page === "new") {
        return PageHelper.newItemModel();
      }

      Api.page("get", this.$route.params.page).then(({ data }) => {
        this.item = data;
        if (this.$route.params.page === "faq") {
          this.questions = this.item.questions;
        }
      });
    },
    save() {
      this.item.path = this.item.path.toLowerCase();

      if (
        this.$route.params.page === "new" &&
        localStorage.reserved.includes(this.item.path)
      ) {
        this.$toast.error(this.$vuetify.lang.t("$vuetify.site.uri_reserved"));
        return;
      }

      var path =
        this.item.path != "/" ? this.item.path.substr(1) : this.item.name.en;

      if (path == "faq") {
        this.item.questions = this.questions;
      }

      Api.page("set", path, this.item).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.create_success"));
        this.$router.push("/manager/site/pagelist");
      });
    },
    closeEditMemberPopup(updatedData) {
      if (!updatedData) return (this.editMemberPopup = false);

      var itemIndex = this.getIndexById(this.item.members, updatedData.id);

      updatedData.content.ka = updatedData.content.ka.replace(this.repl, '');
      updatedData.content.en = updatedData.content.en.replace(this.repl, '');

      this.item["members"][itemIndex].content = updatedData.content;

      if (updatedData.logoNew.ka) {
        let formData = new FormData();
        formData.append("file", updatedData.logoNew.ka);
        Api.media("upload", formData).then(({ data }) => {
          this.item["members"][itemIndex]["logo"]["ka"] = data.url;
        });
      }

      if (updatedData.logoNew.en) {
        let formData = new FormData();
        formData.append("file", updatedData.logoNew.en);
        Api.media("upload", formData).then(({ data }) => {
          this.item["members"][itemIndex]["logo"]["en"] = data.url;
        });
      }

      this.editMemberPopup = false;
    },
    closeEditQuestionPopup(updatedData) {
      if (!updatedData) return (this.editQuestionPopup = false);
      var itemIndex = this.getIndexById(this.questions, updatedData.id);
      
      updatedData.answer.ka = updatedData.answer.ka.replace(this.repl, '');
      updatedData.answer.en = updatedData.answer.en.replace(this.repl, '');

      this.questions[itemIndex] = updatedData;
      this.editQuestionPopup = false;
    },
    addMember() {
      var newItem = JSON.parse(JSON.stringify(MemberModel));
      newItem.id = this.setMaxId(this.item.members);
      this.item.members.push(newItem);
    },
    addFaq() {
      var newItem = JSON.parse(JSON.stringify(FaqModel));
      newItem.id = this.setMaxId(this.questions);
      newItem.question.ka += newItem.id
      this.questions.push(newItem);
    },
    popupDelete(item) {
      this.itemToRemove = item;
      this.confirmRemoval = true;
    },
    removeItem(itemId) {
      if (this.item.path == "/members") {
        this.item.members = this.item.members.filter(function (el) {
          return el.id !== itemId;
        });
      } else if (this.item.path == "/faq") {
        this.questions = this.questions.filter(function (el) {
          return el.id !== itemId;
        });
      }

      this.confirmRemoval = false;
      this.itemToRemove = false;
    },
    getIndexById(items, id) {
      console.log(items)
      console.log(id)
      return items
        .map(function (e) {
          return e.id;
        })
        .indexOf(id);
    },
    setMaxId(items) {
      let maxId = 1;
      if (items.length == 0) return maxId;
      for (const property in items) {
        if (maxId < items[property].id) {
          maxId = items[property].id;
        }
      }

      return ++maxId;
    },
  },
};
</script>
<style scoped>
.member {
  width: 100%;
  height: 250px;
  overflow: hidden;
  border: 1px solid #c1c1c1;
  border-top: 7px solid #f26334;
  padding: 10px 10px;
}
.img {
  width: 35%;
  min-width: 35%;
  height: 100%;
  background-size: 85%;
  background-position: top center;
  background-repeat: no-repeat;
  margin-right: 10px;
  float: left;
}
.desc {
  width: 55%;
  min-width: 55%;
  height: 100%;
  float: left;
}
.member button {
  margin-top: -40px;
}
</style>