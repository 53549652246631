<template>
  <div>
    <template v-for="block in model">
      <div class="block" :key="block.id" :id="'block_' + block.id">
        <Editor :model="block.content" delete="block.id" />
      </div>
    </template>
  </div>
</template>
<script>
import Editor from "@/components/manager/site/Editor.vue";

export default {
  props: ["model"],
  components: {
    Editor,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.block {
  display: inline-block;
  margin: 0px 10px 10px 0px;
  width: calc(100% / 2.1);
}
@media only screen and (max-width: 820px) {
  .block {
    width: 100%;
  }
}
</style>