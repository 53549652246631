export default {
    id: null,
    question: {
        ka: "კითხვა",
        en: "Question",
    },
    answer: {
        ka: "პასუხი",
        en: "Answer",
    },
}