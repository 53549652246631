import * as HelperModel from '@/models/page.js'

class PageHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }
}

const PageHelper = new PageHelpers
export default PageHelper