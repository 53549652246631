<template>
  <v-dialog
    v-if="dialogState"
    v-model="dialogState"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.edit") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <Editor :model="dialogState.content" />
        <v-row>
          <v-col>
            {{ $vuetify.lang.t("$vuetify.manager.logo_ka") }}
            <v-file-input
              show-size
              truncate-length="30"
              v-model="dialogState['logoNew']['ka']"
            ></v-file-input>
          </v-col>
          <v-col>
            {{ $vuetify.lang.t("$vuetify.manager.logo_en") }}
            <v-file-input
              show-size
              truncate-length="30"
              v-model="dialogState['logoNew']['en']"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.closeEditMemberPopup()">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="$parent.closeEditMemberPopup(dialogState)">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@/components/manager/site/Editor.vue";
export default {
  components: { Editor },
  data: function () {
    return {
      dialogState: false,
    };
  },
  watch: {
    member(newVal) {
      if (newVal) {
        this.dialogState = JSON.parse(JSON.stringify(newVal));
        this.dialogState["logoNew"] = {
          ka: null,
          en: null,
        };
      } else {
        this.dialogState = false;
      }
    },
  },
  props: ["member"],
};
</script>